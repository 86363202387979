// If you don't want to use TypeScript you can delete this file!
import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import TiaIcon from 'src/images/tia-icon-without-ball.svg'
import classes from './page.module.css'
import Content from 'src/components/content'

import { SEO } from 'src/components/seo'
import cookie from 'js-cookie'

export interface PageProps {
  pageContext: {
    main: {
      modules: []
      splitTestingEnabled: boolean
      control: string
      variation: string
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Page = ({ path, pageContext }: PageProps) => {
  const {
    main: { modules, slug },
    meta,
    env,
  } = pageContext

  const lastVariant = cookie.get(`split-testing-${path}`)

  useEffect(() => {
    if (pageContext.main.splitTestingEnabled && typeof window !== 'undefined') {
      if (lastVariant) {
        window.location.href = lastVariant
      } else if (Math.random() < 0.5) {
        cookie.set(`split-testing-${path}`, pageContext.main.control, {
          expires: 30,
        })
        window.location.href = pageContext.main.control
      } else {
        cookie.set(`split-testing-${path}`, pageContext.main.variation, {
          expires: 30,
        })
        window.location.href = pageContext.main.variation
      }
    }
  }, [lastVariant, pageContext])

  const url = slug.current === 'home' ? '' : path
  return pageContext.main.splitTestingEnabled ? (
    <div className={classes.pageInner}>
      <img
        className={classes.bounce}
        src={TiaIcon}
        width="128px"
        height="68px"
        alt="tia logo"
      />
    </div>
  ) : (
    <>
      <SEO metaInfo={meta} pagePath={url} env={env} />
      <Content modules={modules}></Content>
    </>
  )
}

export default Page
